import { BiUser } from "react-icons/bi";

import styles from "./SignUpSignInButton.module.scss";
import Button from "@/components/ui/buttons/Button/Button";

export default function SignUpSignInButton({
  onClick,
  buttonRef,
  mode,
  children,
}) {
  if (mode === "button") {
    return (
      <div ref={buttonRef}>
        <Button onClick={onClick} classes={["sign-up-sign-in-button"]}>
          {children ?? "Sign Up/Sign In"}
        </Button>
      </div>
    );
  }

  if (mode === "buttons") {
    return (
      <div ref={buttonRef} className={`${styles.buttons}`}>
        <Button
          onClick={(event) => onClick(event, "signUp")}
          classes={["sign-up-sign-in-button"]}
        >
          Sign Up
        </Button>
        <Button
          onClick={(event) => onClick(event, "signIn")}
          classes={["sign-up-sign-in-button"]}
        >
          Sign In
        </Button>
      </div>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`${styles.userButton} ${styles.custom} sign-up-sign-in-button`}
      ref={buttonRef}
    >
      {children ?? <BiUser size={27} color="white" />}
    </button>
  );
}
